import React, { useState } from "react";
import {
  MenuItem,
  TextField,
  CardHeader,
  Divider,
  Avatar,
  Alert,
  Autocomplete,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { history } from "app/redux/store";
import InputMask from "react-input-mask";
import api from "../../../../services/config";
import { useSelector } from "react-redux";

const FormCliente = ({ user, setLoading }) => {
  const { auth } = useSelector((state) => state);

  const [companies, setCompanies] = useState([]);
  const [photo, setPhoto] = useState(null);
  const [alertParams, setAlertParams] = useState({
    type: "",
    message: "",
    show: false,
  });

  const [company, setCompany] = useState(null);
  const [maskDocumentValue, setMaskDocumentValue] = useState("");

  const validationSchema = Yup.object({
    name: Yup.string().required("Campo obrigatório"),
    email: Yup.string()
      .email("Informe um e-mail válido")
      .required("Campo obrigatório"),
    cel: Yup.string().required("Campo obrigatório"),
    cpf: Yup.string().required("Campo obrigatório"),
    empresa: Yup.string().optional(),
    status: Yup.string().required("Campo obrigatório"),
    role: Yup.string().required("Campo obrigatório"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      cel: "",
      cpf: "",
      empresa: "",
      status: "Ativo",
      role: "",
    },
    validationSchema: validationSchema,
    onSubmit: submitForm,
  });

  async function searchCompanies(key) {
    if (key.length < 2) return;

    try {
      console.log("KEY > > ", key);
      const { data } = await api.get("api/v2/company/autocomplete", {
        params: {
          terms: key,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      setCompanies(
        data.map((d) => {
          return {
            id: d.id,
            label: d.name,
          };
        })
      );

      setTimeout(() => {
        if (data.length == 1) {
          formik.setFieldValue("empresa", data[0].id);
          formik.setFieldValue("empresa", data[0].name);
          setCompany(data[0].id);
        }
      }, 500);
    } catch (error) {
      console.log("ERROR SEARCH COMPANY > > ", error);
    }
  }

  async function submitForm(dataCliente) {
    if (photo) {
      dataCliente["foto"] = photo;
    }

    const formData = new FormData();

    Object.keys(dataCliente).map((key) => {
      if (dataCliente[key]) {
        formData.append(key, key == "empresa" ? company : dataCliente[key]);
      }
    });

    setLoading(true);
    try {
      if (user) {
        const { data } = await api.put(
          `api/v2/dashboard/clientes/${user.id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
      } else {
        const { data } = await api.post(`api/v2/dashboard/clientes`, formData, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
      }

      setAlertParams({
        type: "success",
        message: `Cliente ${user ? "atualizado" : "criado"} com sucesso`,
        show: true,
      });
    } catch (error) {
      console.log("ERROR CREATE TECNICO > > ", error);
      setAlertParams({
        type: "error",
        message:
          error.response.status == 400
            ? "O email informado já está em uso!"
            : `Ocorreu um erro ao ${user ? "atualizar" : "criar"} o cliente.`,
        show: true,
      });
    }
    window.scrollTo(0, 0);
    setLoading(false);
  }

  const maskBuilder = () => {
    const doc = formik.values.cpf.replaceAll(".", "").replaceAll("-", "");
    console.log(doc);

    return doc.length > 11 ? "99.999.999/9999-99" : "999.999.999-99";
  };

  React.useEffect(() => {
    if (user) {
      formik.setFieldValue("name", user.name);
      formik.setFieldValue("email", user.email);
      formik.setFieldValue("cel", user.cel);
      formik.setFieldValue("cpf", user.cpf);

      searchCompanies(user.empresa);

      /* setTimeout(() => {
                formik.setFieldValue("empresa", user.company_id);
            }, 1000); */

      formik.setFieldValue("status", user.status);
      formik.setFieldValue("role", user.roles[0]?.name);
    }
  }, [user]);

  async function desativaCliente() {
    setLoading(true);
    try {
      const { data } = await api.delete(
        `api/v2/dashboard/clientes/del/${user.id}`,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      console.log(data);
      setAlertParams({
        type: "success",
        message: `Cliente desativado com sucesso`,
        show: true,
      });
      window.location.reload();
    } catch (error) {
      console.log("ERROR > > ", error);
      setAlertParams({
        type: "error",
        message: `Ocorreu um erro ao desativar cliente`,
        show: true,
      });
    }
    window.scrollTo(0, 0);
    setLoading(false);
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      {alertParams.show && (
        <Alert
          sx={{ m: 2 }}
          variant="filled"
          severity={alertParams.type}
          onClose={() => {
            setAlertParams({
              ...alertParams,
              show: false,
            });
          }}
        >
          {alertParams.message}
        </Alert>
      )}

      <CardHeader title="Dados do Cliente" sx={{ mt: -1, ml: -1 }} />
      <Divider sx={{ mb: 2, mt: -1 }} />

      <Div
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Div
          onClick={() => {
            const input = document.getElementById("photo-upload");
            input.click();
          }}
          sx={{
            mb: 3,
            width: 200,
            height: 200,
          }}
        >
          <Avatar
            sx={{
              width: 200,
              height: 200,
              bgcolor: "#cecece",
              cursor: "pointer",
            }}
            src={
              photo
                ? URL.createObjectURL(photo)
                : `https://storage.googleapis.com/uteky/images/tecnicos/${
                    user ? user.id : ""
                  }_100x100.jpg?random=${Math.floor(Math.random() * 10000)}`
            }
          />
        </Div>
      </Div>

      <input
        type="file"
        id="photo-upload"
        accept="image/*"
        onChange={(e) => {
          setPhoto(e.target.files[0]);
        }}
        style={{
          display: "none",
        }}
      />

      <Divider sx={{ mb: 2, mt: -1 }} />

      <FormControl>
        <TextField
          fullWidth
          id="name"
          label="Nome"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
      </FormControl>
      <FormControl>
        <TextField
          fullWidth
          id="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          autoComplete="new-password"
        />
      </FormControl>
      <FormControl>
        <InputMask
          mask="(99) 99999-9999"
          disabled={false}
          value={formik.values.cel}
          onChange={formik.handleChange}
          maskChar=" "
        >
          {() => (
            <TextField
              fullWidth
              id="cel"
              label="Telefone"
              error={formik.touched.cel && Boolean(formik.errors.cel)}
              helperText={formik.touched.cel && formik.errors.cel}
            />
          )}
        </InputMask>
      </FormControl>
      <FormControl>
        <InputMask
          mask={
            maskDocumentValue.length < 15
              ? "999.999.999-999"
              : "99.999.999/9999-99"
          }
          disabled={false}
          value={formik.values.cpf}
          onChange={(e) => {
            setMaskDocumentValue(e.target.value);
            formik.handleChange(e);
          }}
          maskChar=""
        >
          {() => (
            <TextField
              fullWidth
              id="cpf"
              label="CPF/CNPJ"
              error={formik.touched.cpf && Boolean(formik.errors.cpf)}
              helperText={formik.touched.cpf && formik.errors.cpf}
            />
          )}
        </InputMask>
      </FormControl>
      <FormControl>
        <Autocomplete
          disablePortal
          options={companies}
          fullWidth
          value={formik.values.empresa}
          onChange={(e, value) => {
            setCompany(value.id);
            formik.setFieldValue("empresa", value.label);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={(e) => searchCompanies(e.target.value)}
              fullWidth
              id="empresa"
              name="empresa"
              label="Empresa"
              value={formik.values.empresa}
              error={formik.touched.empresa && Boolean(formik.errors.empresa)}
              helperText={formik.touched.empresa && formik.errors.empresa}
            />
          )}
        />
      </FormControl>
      <FormControl>
        <TextField
          fullWidth
          select
          id="status"
          name="status"
          label="Status"
          value={formik.values.status}
          onChange={formik.handleChange}
          error={formik.touched.status && Boolean(formik.errors.status)}
          helperText={formik.touched.status && formik.errors.status}
        >
          <MenuItem value="Ativo">Ativo</MenuItem>
          <MenuItem value="Desativado">Desativado</MenuItem>
        </TextField>
      </FormControl>
      <FormControl>
        <TextField
          fullWidth
          id="role"
          name="role"
          select
          label="Tipo de Usuário"
          value={formik.values.role}
          onChange={formik.handleChange}
          error={formik.touched.role && Boolean(formik.errors.role)}
          helperText={formik.touched.role && formik.errors.role}
        >
          <MenuItem value="Cliente">Cliente</MenuItem>
          <MenuItem value="Abastecedor">Abastecedor</MenuItem>
        </TextField>
      </FormControl>
      <Div sx={{ mx: 1 }}>
        <Button
          variant={"contained"}
          color="inherit"
          type="reset"
          sx={{ mr: 1 }}
          onClick={() => history.goBack()}
        >
          Cancelar
        </Button>
        <Button variant={"contained"} type="submit" sx={{ mr: 1 }}>
          Salvar
        </Button>
        {window.location.href.includes("/editar") && (
          <Button
            variant={"contained"}
            color="error"
            type="reset"
            onClick={desativaCliente}
          >
            Desativar
          </Button>
        )}
      </Div>
    </form>
  );
};

export default FormCliente;
